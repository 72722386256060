import React, { useState } from "react"
import { Link } from "gatsby"
import "./PropertyCard.scss"
import { ShowProcessedImage } from "../common/ggfx-client/module/components/show-image"
import imageConfig from "../../../static/images/config.json"
import GetGGFXImage from "../common/site/get-ggfx-image"
import NoImage from "../../images/no-image.png"
import { roundDecimal } from "../property-search/utils"
import { CurrConvertPrice } from '../../components/currency-converter/CurrConvertPrice'

import { UserObjectStoreProvider, SaveItem } from "@starberry/myaccount-website-utils"

const NewsPropertyCard = props => {
  const propertyitem = props

  const [icon, setIcon] = useState(false)
  const showIcon = () => {
    setIcon(!icon)
  }
  return (
    <React.Fragment> 
        <div className="property-card img-zoom">
          <div className="properties-wrapper">
            <Link to={props.propertyDetailsLink} className="d-inline-block">
              <picture>
                {props.card_type == "similar_property" && props?.propertyImages?.length !== 0 ? (
                  <GetGGFXImage
                    imagename={"property.images.similarpropertytile"}
                    imagesources={
                      propertyitem.propertyImages &&
                      propertyitem.propertyImages[0]
                    }
                    fallbackalt={propertyitem.location}
                    imagetransformresult={props.processedImages}
                    id={props.propertyid}
                  />
                ) : propertyitem.propertyImages &&
                  propertyitem.propertyImages.length == 0 ? (
                  <img
                    src={NoImage}
                    alt={propertyitem.location + " - MCcone Properties"}
                  />
                ) : (
                  <ShowProcessedImage
                    images={
                      propertyitem.propertyImages &&
                      propertyitem.propertyImages[0]
                    }
                    attr={{
                      className: "mb-0 img-fluid",
                      alt: propertyitem.location + " - Mccone Properties",
                    }}
                    transforms={imageConfig.property.searchResults.sizes}
                  />
                )}
              </picture>
            </Link>
          </div>
          <div className="about-properties">
            <div className="properties-info"> 
              <p className="prop-result-description text-truncate">{propertyitem.description}</p>
              <p className="location">{propertyitem.location}</p>
            </div>  
          </div>
        </div>
      
    </React.Fragment>
  )
}

export default NewsPropertyCard
