import React, { useState } from "react"
import { Container, Row, Col } from "react-bootstrap"
import { AnimationOnScroll } from "react-animation-on-scroll"
import ContactCard from "../../components/ContactSidebarCard/ContactSidebar"
import ContactCardWO from "../ContactSidebar/ContactSidebarNoStaff"
import GetGatsbyImage from "../common/site/get-gatsby-image"
import GenerateLink from "../common/site/GetLink"
import { connectMenu, connectHits } from "react-instantsearch-dom"
import "./NewsDetailSection.scss"
import GetGGFXImage from "../common/site/get-ggfx-image"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import { useWindowSize } from "../../hooks/window-size"
import Share from "../share/share"
import moment from "moment"
import SiteBreadcrumbs from "../BreadCrumbs/site-breadcrumbs"
import Sticky from 'react-stickynode';
import parse from "html-react-parser"
import NewsPropertyCard from '../PropertyCard/NewsPropertyCard';
import Help from "../Help/Help"
import PlayVideo from "../PlayVideo/PlayVideo";
import darkBrandLogo02 from "../../images/logo-dark-tab.svg";


const NewsSocial = ({ news }) => {
  return (
    <div className="news-social">
      <h3>Talk to us on your favorite channel</h3>
      <div className="btn-group">
        <a className="email btn" href="mailto:info@mcconeproperties.com">

          <i className="icon-envelope"></i> Email
        </a>
        <a className="call btn" href="tel:+97143806683">

          <i className="icon-contact"></i> Call
        </a>
        <a className="whatsapp btn" href="https://wa.me/+971585138750?text="> 
          <i className="icon-whatsapp"></i> WhatsApp
        </a>
        <a className="instagram btn" href="https://www.instagram.com/mcconeproperties/?hl=en"> 
          <i className="icon-instagram"></i> Instagram
        </a>
      </div>


    </div>
  )
}

export default NewsSocial
